import React from "react";
import $ from "jquery";
import axios from 'axios';

import "../assets/css/style.css";
import logo from "../assets/img/logo.png";


const pathname = window.location.pathname;
var route = pathname.split("/");
let page = route[1];

console.log("page:" + page)

class Main extends React.Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }

    submitpaper(e) {
        e.preventDefault();
        const pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
        const phonepattern = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i;

        if ($("#paperTitle").val() == "") {
            alert("Enter the paper title");
            $("#paperTitle").focus()
        }
        else if ($("#research").val() == "") {
            alert("Select research field");
            $("#research").focus()
        }
        else if ($("#conferenceid").val() == "") {
            alert("Select conference id");
            $("#conferenceid").focus()
        }
        else if ($("#author").val() == "") {
            alert("Enter the author name");
            $("#autor").focus()
        }
        else if ($("#designation").val() == "") {
            alert("Enter the designation");
            $("#designation").focus()
        }
       
        else if ($("#institute").val() == "") {
            alert("Enter the institution");
            $("#institute").focus()
        }
        else if ($("#phone").val() == "" || !phonepattern.test($("#phone").val())) {
            alert("Enter the valid phone");
            $("#phone").focus()
        }
        else if ($("#email").val() == "" || !pattern.test($("#email").val())) {
            alert("Enter the valid email");
            $("#email").focus()
        }
        else if ($("#file").val() == "") {
            alert("Select the file to upload");
            $("#file").focus()
        } 
        else{
            alert("Confirmation mail has been sent to your mail address")
        } 
    }

    cancelsubmission(e)
    {
        e.preventDefault();
        $("#file").val("")
        $("#email").val("")
        $("#phone").val("")
        $("#institute").val("")
        $("#designation").val("")
        $("#author").val("")
        $("#conferenceid").val("")
        $("#research").val("")
        $("#paperTitle").val("")
    }


    render() {
        return (
            <div>
                <header>
                    <div className="logo" title="Conference Management System">
                        <img src={logo} alt="CMS" />
                        <h2>C<span className="danger">M</span>S</h2>
                    </div>
                    <div className="navbar">
                        <a href="/">
                            <span className="material-icons-sharp">home</span>
                            <h3>Home</h3>
                        </a>
                        <a href="submission" className="active">
                            <span className="material-icons-sharp">send</span>
                            <h3>Submission</h3>
                        </a>
                        <a href="register">
                            <span className="material-icons-sharp">app_registration</span>
                            <h3>Register</h3>
                        </a>

                    </div>
                </header>
                <div className="submission-container">
                    <form action="">
                        <h2>Paper Submission</h2>
                        <div className="box">
                            <p className="text-muted">Paper title</p>
                            <input type="text" id="paperTitle" />
                        </div>
                        <div className="box">
                            <p className="text-muted">Research area</p>
                            <select name="research" id="research">
                                <option value=""></option>
                                <option value="mathematics">Mathematics</option>
                                <option value="engineering">Engineering</option>
                                <option value="lifeSciences">Life Sciences</option>
                                <option value="medicineHealthcare">Medicine & Healthcare</option>
                                <option value="socialSciences">Social Sciences</option>
                            </select>
                        </div>
                        <div className="box">
                            <p className="text-muted">Conference id</p>
                            <select name="conferenceid" id="conferenceid">
                                <option value=""></option>
                                <option value="CMS0023">CMS0023</option>
                                <option value="CMS0045">CMS0045</option>
                                <option value="CMS0082">CMS0082</option>
                            </select>
                        </div>
                        <div className="box">
                            <p className="text-muted">Author name</p>
                            <input type="text" id="author" />
                        </div>
                        <div className="box">
                            <p className="text-muted">Designation</p>
                            <input type="text" id="designation" />
                        </div>
                        <div className="box">
                            <p className="text-muted">Institute/Organization</p>
                            <input type="text" id="institute" />
                        </div>
                        <div className="box">
                            <p className="text-muted">Phone number</p>
                            <input type="text" id="phone" />
                        </div>
                        <div className="box">
                            <p className="text-muted">Email</p>
                            <input type="text" id="email" />
                        </div>
                        <div className="box">
                            <p className="text-muted">Upload paper</p>
                            <input type="file" id="file" />
                        </div>
                        <div className="button">
                            <input type="submit" value="Submit" className="btn" onClick={(e) => this.submitpaper(e)} />
                            <a href="$" onClick={(e) => this.cancelsubmission(e)} className="text-muted">Cancel</a>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default Main;