import React from "react";
import $ from "jquery";
import axios from 'axios';

import "../assets/css/style.css";
import logo from "../assets/img/logo.png";


const pathname = window.location.pathname;
var route = pathname.split("/");
let page = route[1];

console.log("page:" + page)

class Main extends React.Component {

    constructor(props) {
        super(props);

    }

    redirect(e) {
        e.preventDefault();
        let url = "/payment"

        if ($("#research").val() == "") {
            alert("Select research field");
            $("#research").focus()
        }
        else if ($("#conferenceid").val() == "") {
            alert("Select conference id");
            $("#conferenceid").focus()
        }
        else if ($("#paperid").val() == "") {
            alert("Enter the paper id sent to you through mail when paper is submited");
            $("#paperid").focus()
        }
        else{
            window.location.replace(url)
        }
    }

    cancelreg(e)
    {
        e.preventDefault();
        $("#paperid").val("")
        $("#conferenceid").val("")
        $("#research").val("")
    }


    render() {
        return (
            <div>
                <header>
                    <div className="logo" title="Conference Management System">
                        <img src={logo} alt="CMS" />
                        <h2>C<span className="danger">M</span>S</h2>
                    </div>
                    <div className="navbar">
                        <a href="/">
                            <span className="material-icons-sharp">home</span>
                            <h3>Home</h3>
                        </a>
                        <a href="register" className="active">
                            <span className="material-icons-sharp">app_registration</span>
                            <h3>Register</h3>
                        </a>

                    </div>
                </header>
                <div className="submission-container">
                    <form action="">
                        <h2>Register</h2>
                        <div className="box">
                            <p className="text-muted">Research area</p>
                            <select name="research" id="research">
                                <option value=""></option>
                                <option value="mathematics">Mathematics</option>
                                <option value="engineering">Engineering</option>
                                <option value="lifeSciences">Life Sciences</option>
                                <option value="medicineHealthcare">Medicine & Healthcare</option>
                                <option value="socialSciences">Social Sciences</option>
                            </select>
                        </div>
                        <div className="box">
                            <p className="text-muted">Conference id</p>
                            <select name="conferenceid" id="conferenceid">
                                <option value=""></option>
                                <option value="CMS0023">CMS0023</option>
                                <option value="CMS0045">CMS0045</option>
                                <option value="CMS0082">CMS0082</option>
                            </select>
                        </div>
                        <div className="box">
                            <p className="text-muted">Paper ID (have been sent to you through mail after paper submission)</p>
                            <input type="text" id="paperid" />
                        </div>
                        <div className="box">
                            <p className="text-muted">Registration Fee</p>
                            <input type="text" id="fee" value="$120" disabled/>
                        </div>
                        
                        <div className="button">
                            <input type="submit" value="Register" className="btn" onClick={(e) => this.redirect(e)}/>
                            <a href="#" onClick={(e) => this.cancelreg(e)} className="text-muted">Cancel</a>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default Main;