import React from "react";
import $ from "jquery";
import axios from 'axios';

import "../assets/css/style.css";
import logo from "../assets/img/logo.png";
import user from "../assets/img/user.png";


const pathname = window.location.pathname;
var route = pathname.split("/");
let page = route[1];

console.log("page:" + page)

class Main extends React.Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }

    download() {
        var content = document.getElementById('content');

        var originalHeight = content.style.height;

        var fullHeight = content.scrollHeight + 'px';

        content.style.height = fullHeight;
        content.style.overflow = 'visible';

        window.html2pdf()
            .from(content)
            .set({
                margin: 1,              
                filename: 'conference-schedule.pdf', 
                html2canvas: { scale: 2 }, 
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            })
            .save()
            .then(() => {
                content.style.height = originalHeight;
                content.style.overflow = "scroll";
            });
    }

    render() {
        return (
            <div>
                <header>
                    <div className="logo" title="Conference Management System">
                        <img src={logo} alt="CMS" />
                        <h2>C<span className="danger">M</span>S</h2>
                    </div>
                    <div className="navbar">
                        <a href="/">
                            <span className="material-icons-sharp">home</span>
                            <h3>Home</h3>
                        </a>
                        <a href="schedule" className="active">
                            <span className="material-icons-sharp">today</span>
                            <h3>Schedule</h3>
                        </a>
                        <a href="joinvirtualconference">
                            <span className="material-icons-sharp">view_in_ar</span>
                            <h3>Virtual Conference</h3>
                        </a>
                    </div>
                </header>
                <div className="container">
                    <aside>
                        <div className="profile">
                            <h3>Conferences</h3>
                            <div className="about" style={{ "height": "420px", "overflowY": "scroll" }}>
                                <h5>Mathematics</h5>
                                <p>1. Mathematical Logic and Foundations <a style={{ "color": "blue" }} href="#">See schedule</a></p>
                                <h5>Engineering</h5>
                                <p>1. Engineering & Industry (general) <a style={{ "color": "blue" }} href="#">See schedule</a>
                                    <br />2. Aviation & Spaceflight <a style={{ "color": "blue" }} href="#">See schedule</a></p>
                                <h5>Life Sciences</h5>
                                <p>1. Molecular Biology <a style={{ "color": "blue" }} href="#">See schedule</a></p>
                                <h5>Medicine & Healthcare</h5>
                                <p>1. Clinical Data Management, e-Health <a style={{ "color": "blue" }} href="#">See schedule</a>
                                    <br />2. Regulation in Medicine and Pharmacology <a style={{ "color": "blue" }} href="#">See schedule</a></p>
                                <h5>Social Sciences</h5>
                                <p>1. Economics, Business and Marketing <a style={{ "color": "blue" }} href="#">See schedule</a>
                                    <br />2. Urbanization and Social Well-being <a style={{ "color": "blue" }} href="#">See schedule</a></p>
                            </div>
                        </div>
                    </aside>

                    <main id="content" style={{ "height": "450px", "overflowY": "scroll" }}>
                        <span className="material-symbols-outlined" style={{ "float": "right", "color": "#03a9f4", "marginRight":"5px", "fontSize": "35px" }} onClick={(e) => this.download()}>download</span>
                        <h1>Clinical Data Management, e-Health (CMS0082)</h1>
                        <h2>October 20, 2024</h2>
                        <ul>
                            <br />
                            <li>
                                <h3>Keynote Session</h3>
                                <ol>
                                    <li><strong>Time</strong>: 9:00 AM - 10:00 AM</li>
                                    <li><strong>Title</strong>: The Future of AI in Healthcare</li>
                                    <li><strong>Speaker</strong>: Dr. Emily Rogers</li>
                                    <li><strong>Details</strong>: Explore the latest advancements in AI technologies that are transforming healthcare delivery, diagnosis, and patient care.</li>
                                </ol>
                                <div>
                                    <button className="small_button" style={{ "margin": "5px" }}>Speaker profile</button>
                                </div>
                            </li><br />
                            <li>
                                <h3>Session 1: Sustainable Engineering</h3>
                                <ol>
                                    <li><strong>Time</strong>: 10:30 AM - 11:30 AM</li>
                                    <li><strong>Title</strong>: Innovations in Green Energy Solutions</li>
                                    <li><strong>Speaker</strong>: Prof. Michael Green</li>
                                    <li><strong>Details</strong>: A session on emerging green technologies and sustainable energy solutions.</li>
                                </ol>
                                <div>
                                    <button className="small_button" style={{ "margin": "5px" }}>Speaker profile</button>
                                </div>
                            </li><br />
                            <li>
                                <h3>Session 2: Advances in Robotics</h3>
                                <ol>
                                    <li><strong>Time</strong>: 12:00 PM - 1:30 PM</li>
                                    <li><strong>Title</strong>: Autonomous Robots: Trends and Applications</li>
                                    <li><strong>Speaker</strong>: Dr. Jonathan Clarke</li>
                                    <li><strong>Details</strong>: This session covers the latest trends in autonomous robotics, focusing on applications in industries like manufacturing, logistics, and healthcare.</li>
                                </ol>
                                <div>
                                    <button className="small_button" style={{ "margin": "5px" }}>Speaker profile</button>
                                </div>
                            </li>
                        </ul>

                        <br />
                    </main>

                    <div className="right">
                        <div className="announcements">
                            <h2>Speaker Profile</h2>
                            <div className="updates">
                                <div className="message">
                                    <div style={{ "display": "flex" }}>
                                        <div class="profile-photo"><img src={user} alt="" /></div>
                                        <div class="info">
                                            <h3>Dr. Emily Rogers</h3>
                                            <small class="text-muted">Chief Scientist, AI Healthcare Innovations</small>
                                        </div>
                                    </div>

                                </div>
                                <div className="message">
                                    <p>
                                        <b>Biography</b> <br />
                                        Dr. Emily Rogers is a renowned expert in artificial intelligence applications in healthcare, with over 15 years of experience in machine learning, diagnostics, and medical research.
                                    </p>
                                </div>
                                <div className="message">
                                    <p>
                                        <b>Presentation</b> <br />
                                        <b>Title:</b> "The Future of AI in Healthcare"<br />
                                        <b>Date/Time:</b> October 20, 2024 | 9:00 AM - 10:00 AM<br />
                                        <b>Abstract:</b> Dr. Rogers will discuss the role of AI in revolutionizing healthcare, from improving diagnostic accuracy to creating personalized treatment plans.
                                    </p>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        );
    }
}

export default Main;