import React from "react";
import $ from "jquery";
import axios from 'axios';

import "../assets/css/style.css";
import logo from "../assets/img/logo.png";


const pathname = window.location.pathname;
var route = pathname.split("/");
let page = route[1];

console.log("page:" + page)

class Main extends React.Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }

    validateExpiryDate(month, year) {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0 for January
        const currentYear = currentDate.getFullYear() % 100; // Get last two digits of the year
      
        // Convert year to two digits if it's in full form (e.g., 2024 to 24)
        const formattedYear = year.length === 4 ? parseInt(year.slice(2)) : parseInt(year);
      
        // Check if the card expiry year and month are valid
        if (formattedYear > currentYear || (formattedYear === currentYear && parseInt(month) >= currentMonth)) {
          return true;
        } else {
          return false;
        }
      }

    pay(e)
    {
        e.preventDefault();

        const creditCardRegex = /^(?:\d[ -]*?){13,19}$/;
        const cvvRegex = /^[0-9]{3,4}$/;
        var expiryDate = $("#expiry").val()
        const [month, year] = expiryDate.split("/");

        if ($("#card").val() == "" || !creditCardRegex.test($("#card").val())) {
            alert("Enter valid card number (13 to 19 digits allowed)");
            $("#card").focus()
        }
        else if (!month || !year || !this.validateExpiryDate(month, year)) {
            alert("Enter valid expiry date in MM/YY or MM/YYYY format");
            $("#expiry").focus()
        }
        else if ($("#cvv").val() == "" || !cvvRegex.test($("#cvv").val())) {
            alert("Enter valid CVV (3 to 4 digits allowed)");
            $("#cvv").focus()
        }
        else{
            alert("Confirmation mail has been sent to your mail address")
        }
    }

    cancelpay(e)
    {
        e.preventDefault();
        $("#cvv").val("")
        $("#expiry").val("")
        $("#card").val("")
    }

    render() {
        return (
            <div>
                <header>
                    <div className="logo" title="Conference Management System">
                        <img src={logo} alt="CMS" />
                        <h2>C<span className="danger">M</span>S</h2>
                    </div>
                    <div className="navbar">
                        <a href="/">
                            <span className="material-icons-sharp">home</span>
                            <h3>Home</h3>
                        </a>
                        <a href="register" className="active">
                            <span className="material-icons-sharp">app_registration</span>
                            <h3>Register</h3>
                        </a>

                    </div>
                </header>
                <div className="submission-container">
                    <form action="">
                        <h2>Payment</h2>
                        <div className="box">
                            <p className="text-muted">Payment Amount</p>
                            <p>$120</p>
                        </div>
                        <div className="box">
                            <p className="text-muted">Card number</p>
                            <input type="text" id="card"/>
                        </div>
                        <div className="box">
                            <p className="text-muted">Expiry date</p>
                            <input type="text" id="expiry" placeholder="MM/YY or MM/YYYY"/>
                        </div>
                        <div className="box">
                            <p className="text-muted">CVV</p>
                            <input type="text" id="cvv"/>
                        </div>
                        <div className="button">
                            <input type="submit" value="Pay" className="btn" onClick={(e) => this.pay(e)}/>
                            <a href="#" onClick={(e) => this.cancelpay(e)} className="text-muted">Cancel</a>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default Main;