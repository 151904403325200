
import './App.css';

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "./components/Home.jsx";
import CallForPaper from "./components/CallForPaper.jsx";
import Submission from "./components/Submission.jsx";
import Register from "./components/Register.jsx";
import Payment from "./components/Payment.jsx";
import Schedule from "./components/Schedule.jsx";
import Contact from "./components/ContactUs.jsx";
import Review from "./components/Review.jsx";
import JoinVirtualConference from "./components/JoinVirtaulConference.jsx";
import VirtualConference from "./components/VirtualConference.jsx";
import Login from "./components/Login.jsx";
import Aconferences from "./components/AConferences.jsx";

const pathname = window.location.pathname;

function App() {

  var route = pathname.split("/");
  console.log(route);
  console.log(route[1]);

  if (route[1] == 'callForPaper') {
    return (
      <Router>
        <Switch>
          <Route path="/callForPaper" exact component={() => <CallForPaper />} />
        </Switch>
      </Router>
    );
  }
  else if (route[1] == 'submission') {
    return (
      <Router>
        <Switch>
          <Route path="/submission" exact component={() => <Submission />} />
        </Switch>
      </Router>
    );
  }
  else if (route[1] == 'register') {
    return (
      <Router>
        <Switch>
          <Route path="/register" exact component={() => <Register />} />
        </Switch>
      </Router>
    );
  }
  else if (route[1] == 'payment') {
    return (
      <Router>
        <Switch>
          <Route path="/payment" exact component={() => <Payment />} />
        </Switch>
      </Router>
    );
  }
  else if (route[1] == 'schedule') {
    return (
      <Router>
        <Switch>
          <Route path="/schedule" exact component={() => <Schedule />} />
        </Switch>
      </Router>
    );
  }
  else if (route[1] == 'contactus') {
    return (
      <Router>
        <Switch>
          <Route path="/contactus" exact component={() => <Contact />} />
        </Switch>
      </Router>
    );
  }
  else if (route[1] == 'review') {
    return (
      <Router>
        <Switch>
          <Route path="/review" exact component={() => <Review />} />
        </Switch>
      </Router>
    );
  }
  else if (route[1] == 'virtualconference') {
    return (
      <Router>
        <Switch>
          <Route path="/virtualconference" exact component={() => <VirtualConference />} />
        </Switch>
      </Router>
    );
  }
  else if (route[1] == 'joinvirtualconference') {
    return (
      <Router>
        <Switch>
          <Route path="/joinvirtualconference" exact component={() => <JoinVirtualConference />} />
        </Switch>
      </Router>
    );
  }

  else if (route[1] == 'admin') {
    return (
      <Router>
        <Switch>
          <Route path="/admin/login" exact component={() => <Login />} />
          <Route path="/admin/conferences" exact component={() => <Aconferences />} />
        </Switch>
      </Router>
    );
  }
  else if (route[1] == '') {
    return (
      <Router>
        <Switch>
          <Route path="" exact component={() => <Home />} />
        </Switch>
      </Router>
    );
  }
  else {
    return (
      <h1>Page not found</h1>
    );
  }
}

export default App;



