import React from "react";
import $ from "jquery";
import axios from 'axios';
import LocationPicker from 'react-location-picker';

import "../assets/css/style.css";
import logo from "../assets/img/logo.png";


const pathname = window.location.pathname;
var route = pathname.split("/");
let page = route[1];

console.log("page:" + page)

class Main extends React.Component {

    state = {
        position: {
            lat: 0,
            lng: 0
        },
        defaultPosition: {}
    }

    constructor(props) {
        super(props);
        this.handleLocationChange = this.handleLocationChange.bind(this);
    }

    componentDidMount() {
        var defaultPosition = {lat: 32.73306102089013, lng: -97.1139347076416}
        this.setState({ defaultPosition });
    }

    submit(e) {
        e.preventDefault();
        const pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
        const phonepattern = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i;

        if ($("#subject").val() == "") {
            alert("Enter the subject");
            $("#subject").focus()
        }
        else if ($("#message").val() == "") {
            alert("Enter the message");
            $("#message").focus()
        }
        else if ($("#phone").val() == "" || !phonepattern.test($("#phone").val())) {
            alert("Enter the valid phone");
            $("#phone").focus()
        }
        else if ($("#email").val() == "" || !pattern.test($("#email").val())) {
            alert("Enter the valid email");
            $("#email").focus()
        }
        else if ($("#address").val() == "") {
            alert("Enter the conference address");
            $("#address").focus()
        }
        else
        {
            alert("Message has been received, we will get back to you shortly")
        }
    }

    cancelsubmission(e) {
        e.preventDefault();
        $("#email").val("")
        $("#phone").val("")
        $("#message").val("")
        $("#subject").val("")
        $("#address").val("")
    }

    handleLocationChange({ position, address, places }) {
        this.setState({ position, address });
        console.log(position)
        var defaultPosition = position;
        this.setState({ defaultPosition });
    }

    render() {
        return (
            <div>
                <header>
                    <div className="logo" title="Conference Management System">
                        <img src={logo} alt="CMS" />
                        <h2>C<span className="danger">M</span>S</h2>
                    </div>
                    <div className="navbar">
                        <a href="/">
                            <span className="material-icons-sharp">home</span>
                            <h3>Home</h3>
                        </a>
                        <a href="contactus" className="active">
                            <span className="material-icons-sharp">call</span>
                            <h3>Contact us</h3>
                        </a>

                    </div>
                </header>
                <div className="submission-container">
                    <form action="">
                        <h2>Contact us</h2>

                        <div className="box">
                            <p className="text-muted">Subject</p>
                            <input type="text" id="subject" />
                        </div>
                        <div className="box">
                            <p className="text-muted">Message</p>
                            <textarea name="" id="message">

                            </textarea>
                        </div>
                        <div className="box">
                            <p className="text-muted">Phone number</p>
                            <input type="text" id="phone" />
                        </div>
                        <div className="box">
                            <p className="text-muted">Email</p>
                            <input type="text" id="email" />
                        </div>

                        <div className="box">
                            <p className="text-muted">Conference address</p>
                            <textarea id="address"></textarea>
                        </div>

                        <p className="text-muted">Conference location</p>
                        <LocationPicker
                            containerElement={<div style={{ height: '100%' }} />}
                            mapElement={<div style={{ height: '400px' }} />}
                            defaultPosition={this.state.defaultPosition}
                            onChange={this.handleLocationChange}
                        />

                        <div className="button">
                            <input type="submit" value="Submit" className="btn" onClick={(e) => this.submit(e)} />
                            <a href="$" onClick={(e) => this.cancelsubmission(e)} className="text-muted">Cancel</a>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default Main;