import React from "react";
import $ from "jquery";
import axios from 'axios';

import "../assets/css/style.css";
import logo from "../assets/img/logo.png";


const pathname = window.location.pathname;
var route = pathname.split("/");
let page = route[1];

console.log("page:" + page)

class Main extends React.Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <header>
                    <div className="logo" title="Conference Management System">
                        <img src={logo} alt="CMS" />
                        <h2>C<span className="danger">M</span>S</h2>
                    </div>
                    <div className="navbar">
                        <a href="/" className="active">
                            <span className="material-icons-sharp">home</span>
                            <h3>Home</h3>
                        </a>
                        <a href="callForPaper">
                            <span className="material-icons-sharp">ring_volume</span>
                            <h3>Call for Paper</h3>
                        </a>
                        <a href="register">
                            <span className="material-icons-sharp">app_registration</span>
                            <h3>Register</h3>
                        </a>
                        <a href="schedule">
                            <span className="material-icons-sharp">today</span>
                            <h3>Schedule</h3>
                        </a>
                        <a href="review">
                            <span className="material-icons-sharp">rate_review</span>
                            <h3>Review</h3>
                        </a>
                        <a href="contactus">
                            <span className="material-icons-sharp">call</span>
                            <h3>Contact us</h3>
                        </a>
                    </div>
                </header>
                <div className="container">
                    <aside>
                        <div className="profile">
                            <h3>Today's Conferences</h3>
                            <div className="about">
                                <h5>Mathematics</h5>
                                <p>1. Mathematical Logic and Foundations <a style={{ "color": "blue" }} href="/virtualconference">join now</a></p>
                                <h5>Engineering</h5>
                                <p>1. Engineering & Industry (general) <a style={{ "color": "blue" }} href="/virtualconference">join now</a>
                                    <br />2. Aviation & Spaceflight <a style={{ "color": "blue" }} href="/virtualconference">join now</a></p>
                                <h5>Life Sciences</h5>
                                <p>1. Molecular Biology <a style={{ "color": "blue" }} href="#">join now</a></p>
                                <h5>Medicine & Healthcare</h5>
                                <p>1. Clinical Data Management, e-Health <a style={{ "color": "blue" }} href="/virtualconference">join now</a>
                                    <br />2. Regulation in Medicine and Pharmacology <a style={{ "color": "blue" }} href="/virtualconference">join now</a></p>
                                <h5>Social Sciences</h5>
                                <p>1. Economics, Business and Marketing <a style={{ "color": "blue" }} href="/virtualconference">join now</a></p>
                            </div>
                        </div>
                    </aside>

                    <main>
                        <h1>Conference Management System</h1>
                        <p style={{ "margin-top": "10px", "font-size": "16px", "text-align": "justify" }}>
                            This platform is designed to provide you with everything you need for a smooth and engaging virtual conference experience. From here, you can easily navigate the agenda, register for sessions, participate in live discussions, and access key event resources.
                            <br /><br />Our intuitive interface allows you to connect with fellow attendees, explore interactive features, and stay informed with real-time updates. Whether it's browsing speaker profiles or accessing post-event materials, this website is your one-stop solution for managing your conference experience.
                            <br /><br />Thank you for being a part of our community—explore, connect, and enjoy the event!
                        </p>
                        <br />
                        <h2>Upcoming conferences you can register</h2>
                        <div className="home_cards">
                            <div className="eg">
                                <span className="material-icons-sharp">functions</span>
                                <h3>Algebraic Geometry (CMS0023)</h3>
                                <p style={{ "text-align": "justify" }}>Algebraic geometry explores the solutions to systems of polynomial equations and their geometric structures. It connects algebra, geometry, and number theory, and has applications in fields such as cryptography and string theory. </p>
                                <small className="text-muted"><b>Date:</b>10/11/2024
                                <br/><b>Place:</b>The University of Texas at Arlington, Arlington, TX</small><br/>
                                <div>
                                    <button style={{ "margin": "5px" }} className="small_button">Call for paper</button>
                                    <button style={{ "margin": "5px" }} className="small_button">Register</button>
                                    <button style={{ "margin": "5px" }} className="small_button">Schedule</button>
                                </div>
                            </div>
                            <div className="eg">
                                <span className="material-icons-sharp">functions</span>
                                <h3>Mathematical Optimization (CMS0082)</h3>
                                <p style={{ "text-align": "justify" }}>This field deals with finding the best solution from a set of feasible options, often within constraints. It includes linear, non-linear, and combinatorial optimization, with applications in engineering, economics, machine learning, and operations research.</p>
                                <small className="text-muted"><b>Date:</b>15/11/2024
                                <br/><b>Place:</b>Harvard University, Cambridge, MA</small><br/>
                                <div>
                                    <button style={{ "margin": "5px" }} className="small_button">Call for paper</button>
                                    <button style={{ "margin": "5px" }} className="small_button">Register</button>
                                    <button style={{ "margin": "5px" }} className="small_button">Schedule</button>
                                </div>
                            </div>
                            <div className="mth">
                                <span className="material-icons-sharp">architecture</span>
                                <h3>Sustainable Energy Engineering (CMS0038)</h3>
                                <p style={{ "text-align": "justify" }}>This field focuses on developing innovative technologies for clean and renewable energy sources, such as solar, wind, and bioenergy. Research in sustainable energy engineering aims to reduce carbon emissions, improve energy efficiency, and create sustainable solutions for global energy demands.</p>
                                <small className="text-muted"><b>Date:</b>13/11/2024
                                <br/><b>Place:</b>Harvard University, Cambridge, MA</small><br/>
                                <div>
                                    <button style={{ "margin": "5px" }} className="small_button">Call for paper</button>
                                    <button style={{ "margin": "5px" }} className="small_button">Register</button>
                                    <button style={{ "margin": "5px" }} className="small_button">Schedule</button>
                                </div>
                            </div>
                            <div className="cs">
                                <span className="material-icons-sharp">public</span>
                                <h3>Impact of Social Media on Human Relationships (CMS0123)</h3>
                                <p style={{ "text-align": "justify" }}>This topic explores how social media platforms are reshaping communication, relationships, and social behavior. It examines both the positive aspects, such as enhanced connectivity and information sharing, and the challenges, including issues like cyberbullying, social isolation, and the impact on mental health.</p>
                                <small className="text-muted"><b>Date:</b>21/11/2024
                                <br/><b>Place:</b>The University of Texas at Arlington, Arlington, TX</small><br/>
                                <div>
                                    <button style={{ "margin": "5px" }} className="small_button">Call for paper</button>
                                    <button style={{ "margin": "5px" }} className="small_button">Register</button>
                                    <button style={{ "margin": "5px" }} className="small_button">Schedule</button>
                                </div>
                            </div>
                            <div className="cg">
                                <span className="material-icons-sharp">medical_services</span>
                                <h3>Telemedicine and Digital Health (CMS0234)</h3>
                                <p style={{ "text-align": "justify" }}>Telemedicine is revolutionizing healthcare delivery by allowing patients to access medical services remotely. This topic covers advancements in telehealth platforms, virtual consultations, and remote patient monitoring. </p>
                                <small className="text-muted"><b>Date:</b>22/11/2024
                                <br/><b>Place:</b>Harvard University, Cambridge, MA</small><br/>
                                <div>
                                    <button style={{ "margin": "5px" }} className="small_button">Call for paper</button>
                                    <button style={{ "margin": "5px" }} className="small_button">Register</button>
                                    <button style={{ "margin": "5px" }} className="small_button">Schedule</button>
                                </div>
                            </div>
                            <div className="cg">

                                <span className="material-icons-sharp">medical_services</span>
                                <h3>Personalized Medicine (CMS0025)</h3>
                                <p style={{ "text-align": "justify" }}>Personalized medicine focuses on tailoring medical treatment to the individual characteristics of each patient, using genetic, environmental, and lifestyle data. This approach enhances the precision of therapies, particularly in fields like oncology, where targeted therapies can improve patient outcomes. </p>
                                <small className="text-muted"><b>Date:</b>30/11/2024
                                <br/><b>Place:</b>The University of Texas at Arlington, Arlington, TX</small><br/>
                                <div>
                                    <button style={{ "margin": "5px" }} className="small_button">Call for paper</button>
                                    <button style={{ "margin": "5px" }} className="small_button">Register</button>
                                    <button style={{ "margin": "5px" }} className="small_button">Schedule</button>
                                </div>
                            </div>
                        </div>
                    </main>

                    <div className="right">
                        <div className="announcements">
                            <h2>Announcements</h2>
                            <div className="updates">
                                <div className="message">
                                    <p>
                                        <b>Technical Issues? We're Here to Help!</b> <br />
                                        If you’re experiencing any technical issues, visit the Help Desk or reach out via live chat support.
                                    </p>
                                </div>
                                <div className="message">
                                    <p>
                                        <b>Interactive Polls and Q&A During Sessions</b> <br />
                                        Engage with the speakers! Don’t forget to participate in live polls and ask questions during sessions.
                                    </p>
                                </div>
                                <div className="message">
                                    <p>
                                        <b>Virtual Expo Booths Now Open!</b> <br />
                                        Explore the latest innovations from our exhibitors. Visit the virtual expo booths and chat live with representatives.
                                    </p>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        );
    }
}

export default Main;