import React from "react";
import $ from "jquery";
import axios from 'axios';

import "../assets/css/style.css";
import logo from "../assets/img/logo.png";


const pathname = window.location.pathname;
var route = pathname.split("/");
let page = route[1];

console.log("page:" + page)

class Main extends React.Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        $("#reviewerDetails").show()
        $("#paperlist").hide()
        $('#pdfviewer').hide();
        $('#pdfviewermessage').show();
    }

    submitReviewerDetails(e) {
        e.preventDefault();

        $("#reviewerDetails").hide()
        $("#paperlist").show()
    }

    openPDF(e) {
        e.preventDefault();
        $('#pdfviewer').show();
        $('#pdfviewermessage').hide();
    }

    submitFeedback(e)
    {
        e.preventDefault();
        $("#originality")
        if ($("#originality").val() == "" || $("#originality").val() > 10 || $("#originality").val() < 0) {
            alert("Originality score can be between 0 to 10");
            $("#originality").focus()
        }
        else if ($("#clarity").val() == "" || $("#clarity").val() > 10 || $("#clarity").val() < 0) {
            alert("Clarity score can be between 0 to 10");
            $("#clarity").focus()
        }
        else if ($("#relevance").val() == "" || $("#relevance").val() > 10 || $("#relevance").val() < 0) {
            alert("Relevence score can be between 0 to 10");
            $("#relevance").focus()
        }
        else if ($("#comment").val() == "" || $("#originality").val() > 10) {
            alert("Give the feedback");
            $("#comment").focus()
        }
        else{
            alert("Score and feedback is submitted")
        }
    }

    render() {
        return (
            <div>
                <header>
                    <div className="logo" title="Conference Management System">
                        <img src={logo} alt="CMS" />
                        <h2>C<span className="danger">M</span>S</h2>
                    </div>
                    <div className="navbar">
                        <a href="/" >
                            <span className="material-icons-sharp">home</span>
                            <h3>Home</h3>
                        </a>
                        <a href="callForPaper">
                            <span className="material-icons-sharp">ring_volume</span>
                            <h3>Call for Paper</h3>
                        </a>
                        <a href="register">
                            <span className="material-icons-sharp">app_registration</span>
                            <h3>Register</h3>
                        </a>
                        <a href="schedule">
                            <span className="material-icons-sharp">today</span>
                            <h3>Schedule</h3>
                        </a>
                        <a href="review" className="active">
                            <span className="material-icons-sharp">rate_review</span>
                            <h3>Review</h3>
                        </a>
                        <a href="contactus">
                            <span className="material-icons-sharp">call</span>
                            <h3>Contact us</h3>
                        </a>
                    </div>
                </header>
                <div className="container">
                    <aside>
                        <div className="profile" >
                            <h3>Papers to review</h3>
                            <div className="about">
                                <div id="reviewerDetails">
                                    <h5>Reviewer detail</h5><br />
                                    <div className="box reviewer_form">
                                        <select name="research" id="research">
                                            <option value="">Select research area</option>
                                            <option value="mathematics">Mathematics</option>
                                            <option value="engineering">Engineering</option>
                                            <option value="lifeSciences">Life Sciences</option>
                                            <option value="medicineHealthcare">Medicine & Healthcare</option>
                                            <option value="socialSciences">Social Sciences</option>
                                        </select>
                                    </div>
                                    <div className="box reviewer_form">
                                        <input type="text" id="reviewer" placeholder="Reviewer name" />
                                    </div>
                                    <div className="box reviewer_form">
                                        <input type="text" id="designation" placeholder="Designation" />
                                    </div>
                                    <div className="box reviewer_form">
                                        <input type="text" id="institute" placeholder="Institute" />
                                    </div>
                                    <div className="box reviewer_form">
                                        <input type="text" id="phone" placeholder="Phone" />
                                    </div>
                                    <div className="box reviewer_form">
                                        <input type="text" id="email" placeholder="Email" />
                                    </div>
                                    <div className="button">
                                        <input type="submit" value="Submit" className="btn" onClick={(e) => this.submitReviewerDetails(e)} />
                                    </div>
                                </div>

                                <div id="paperlist">
                                    <p>1. Advancements in Telemedicine Post-Pandemic (PaperId #234) <a style={{ "color": "blue" }} href="#" onClick={(e) => this.openPDF(e)}>Open paper</a></p>
                                    <p>2. AI in Medical Imaging: Accuracy and Ethics (PaperId #455) <a style={{ "color": "blue" }} href="#" onClick={(e) => this.openPDF(e)}>Open paper</a></p>
                                    <p>3. The Impact of Genomics on Personalized Medicine (PaperId #653) <a style={{ "color": "blue" }} href="#" onClick={(e) => this.openPDF(e)}>Open paper</a></p>
                                    <p>4. Mental Health Interventions for Frontline Workers (PaperId #332) <a style={{ "color": "blue" }} href="#" onClick={(e) => this.openPDF(e)}>Open paper</a></p>
                                </div>
                            </div>
                        </div>
                    </aside>

                    <main id="content">
                        <h1>Peer review</h1>
                        <p id="pdfviewermessage">Message for Reviewers <br />
                            Please submit the details in reviewers form on the left hand <br />
                            Later you should be able to see assigned papers for review <br />
                            Click on open pdf to start review <br />
                            Once reviewed please put the score and feedback on feedback form on the right hand side</p>
                        <object id="pdfviewer" data='https://pdfobject.com/pdf/sample.pdf#toolbar=1'
                            type='application/pdf'
                            width='100%' height='440px'>
                        </object>
                    </main>

                    <div className="right">
                        <div className="announcements">
                            <h2>Score and feedback</h2>
                            <div className="updates">
                                <div className="message">
                                    <p>
                                        <b>Give your score for below</b> <br />
                                    </p>
                                </div>
                                <div className="box reviewer_form">
                                    <input type="number" id="originality" placeholder="Originality - 1 to 10" />
                                </div>
                                <div className="box reviewer_form">
                                    <input type="number" id="clarity" placeholder="Clarity - 1 to 10" />
                                </div>
                                <div className="box reviewer_form">
                                    <input type="number" id="relevance" placeholder="Relevance - 1 to 10" />
                                </div>

                                <div className="message">
                                    <p>
                                        <b>Give brief feedback about the paper</b> <br />
                                    </p>
                                </div>
                                <div className="box reviewer_form">
                                    <textarea id="comment">

                                    </textarea>
                                </div>
                                <div className="button">
                                        <input type="submit" value="Submit" className="btn" onClick={(e) => this.submitFeedback(e)}/>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        );
    }
}

export default Main;