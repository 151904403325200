import React from "react";
import $ from "jquery";
import axios from 'axios';

import "../assets/css/style.css";
import logo from "../assets/img/logo.png";
import user from "../assets/img/user.png";


const pathname = window.location.pathname;
var route = pathname.split("/");
let page = route[1];

console.log("page:" + page)

class Main extends React.Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }

    download() {
        var content = document.getElementById('content');

        var originalHeight = content.style.height;

        var fullHeight = content.scrollHeight + 'px';

        content.style.height = fullHeight;
        content.style.overflow = 'visible';

        window.html2pdf()
            .from(content)
            .set({
                margin: 1,
                filename: 'conference-schedule.pdf',
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            })
            .save()
            .then(() => {
                content.style.height = originalHeight;
                content.style.overflow = "scroll";
            });
    }

    render() {
        return (
            <div>
                <header>
                    <div className="logo" title="Conference Management System">
                        <img src={logo} alt="CMS" />
                        <h2>C<span className="danger">M</span>S</h2>
                    </div>
                    <div className="navbar">
                        <a href="/admin/conferences" className="active">
                            <span className="material-icons-sharp">book</span>
                            <h3>Conferences</h3>
                        </a>
                        <a href="/admin/login">
                            <span className="material-icons-sharp">logout</span>
                            <h3>Logout</h3>
                        </a>
                    </div>
                </header>
                <div className="container">
                    <aside>
                        <div className="profile">
                            <h3>Conferences</h3>
                            <div className="about" style={{ "height": "420px", "overflowY": "scroll" }}>
                                <h5>Mathematics</h5>
                                <p>1. Mathematical Logic and Foundations <a style={{ "color": "blue" }} href="#">Edit</a></p>
                                <h5>Engineering</h5>
                                <p>1. Engineering & Industry (general) <a style={{ "color": "blue" }} href="#">Edit</a>
                                    <br />2. Aviation & Spaceflight <a style={{ "color": "blue" }} href="#">Edit</a></p>
                                <h5>Life Sciences</h5>
                                <p>1. Molecular Biology <a style={{ "color": "blue" }} href="#">Edit</a></p>
                                <h5>Medicine & Healthcare</h5>
                                <p>1. Clinical Data Management, e-Health <a style={{ "color": "blue" }} href="#">Edit</a>
                                    <br />2. Regulation in Medicine and Pharmacology <a style={{ "color": "blue" }} href="#">Edit</a></p>
                                <h5>Social Sciences</h5>
                                <p>1. Economics, Business and Marketing <a style={{ "color": "blue" }} href="#">Edit</a>
                                    <br />2. Urbanization and Social Well-being <a style={{ "color": "blue" }} href="#">Edit</a></p>
                            </div>
                        </div>
                    </aside>

                    <main id="content" style={{ "height": "450px", "overflowY": "scroll" }} className="conference_form">
                        <h1>Sustainable Energy Engineering (CMS0082)</h1>
                        <ul>
                            <br />
                            <li>
                                <h2>Conference details</h2>
                                <ol>
                                    <li>
                                        <strong>Type:</strong>
                                        <select name="research" id="research">
                                            <option value=""></option>
                                            <option value="mathematics">Mathematics</option>
                                            <option value="engineering" selected>Engineering</option>
                                            <option value="lifeSciences">Life Sciences</option>
                                            <option value="medicineHealthcare">Medicine & Healthcare</option>
                                            <option value="socialSciences">Social Sciences</option>
                                        </select>
                                    </li>
                                    <li>
                                        <strong>Description:</strong>
                                        <textarea name="" style={{ "height": "75px" }} id="desc">This field focuses on developing innovative technologies for clean and renewable energy sources, such as solar, wind, and bioenergy. Research in sustainable energy engineering aims to reduce carbon emissions, improve energy efficiency, and create sustainable solutions for global energy demands.</textarea>
                                    </li>
                                    <li>
                                        <strong>Place:</strong>
                                        <textarea name="" style={{ "height": "30px" }} id="desc">Harvard University, Cambridge, MA</textarea>
                                    </li>
                                </ol>
                            </li><br />
                            <li>
                                <h2>Important dates</h2>
                                <ol>
                                    <li>
                                        <strong>Paper submission date:</strong>
                                        <input type="date" name="" id="paperSubDate" value="2024-09-20" />
                                    </li>
                                    <li>
                                        <strong>Notification of acceptance:</strong>
                                        <input type="date" name="" id="acceptedDate" value="2024-09-25" />
                                    </li>
                                    <li>
                                        <strong>Camera-Ready Paper Submission Deadline:</strong>
                                        <input type="date" name="" id="cameraSubDate" value="2024-09-30" />
                                    </li>
                                    <li>
                                        <strong>Registration Deadline:</strong>
                                        <input type="date" name="" id="regDate" value="2024-10-15" />
                                    </li>
                                    <li>
                                        <strong>Conference Dates:</strong>
                                        <input type="date" name="" id="confDate" value="2024-10-24" />
                                    </li>
                                </ol>
                            </li><br />
                            <li>
                                <h2>Submission requirement</h2>
                                <ol>
                                    <li>
                                        <strong>Originality:</strong>
                                        <textarea name="" style={{ "height": "40px" }} id="originality">Papers must be original and not previously published or under consideration for publication elsewhere.</textarea>
                                    </li>
                                    <li>
                                        <strong>Plagiarism:</strong>
                                        <textarea name="" style={{ "height": "40px" }} id="plagiarism">Submissions should be free of plagiarism. All papers will be checked for originality using plagiarism detection tools.</textarea>
                                    </li>
                                    <li>
                                        <strong>Language:</strong>
                                        <textarea name="" style={{ "height": "40px" }} id="language">Papers must be written in English and carefully proofread for grammar and clarity.</textarea>
                                    </li>
                                    <li>
                                        <strong>File Format:</strong>
                                        <textarea name="" style={{ "height": "40px" }} id="fileFormat">Papers must be submitted in PDF format.</textarea>
                                    </li>
                                    <li>
                                        <strong>Length:</strong>
                                        <textarea name="" style={{ "height": "40px" }} id="length">The paper should be between 6 to 8 pages, including references, figures, and tables.</textarea>
                                    </li>
                                    <li>
                                        <strong>Font:</strong>
                                        <textarea name="" style={{ "height": "40px" }} id="font">Use 10-point Times New Roman or a similar font for the main body text.</textarea>
                                    </li>
                                    <li>
                                        <strong>Title Page:</strong>
                                        <textarea name="" style={{ "height": "40px" }} id="titlePage">The title page should include the paper’s title, authors' names, affiliations, and contact information of the corresponding author.</textarea>
                                    </li>
                                    <li>
                                        <strong>Abstract:</strong>
                                        <textarea name="" style={{ "height": "40px" }} id="abstract">A concise summary of the paper, not exceeding 250 words.</textarea>
                                    </li>
                                    <li>
                                        <strong>Introduction:</strong>
                                        <textarea name="" style={{ "height": "40px" }} id="introduction">Introduce the problem, background, and purpose of your research.</textarea>
                                    </li>
                                </ol>
                            </li>
                        </ul>
                        <button className="btn" style={{ "margin": "5px" }}>Update</button>
                        <br />
                    </main>

                    <div className="right">
                        <div className="announcements">
                            <h2>Add new</h2>
                            <div className="updates">
                                <div className="conference_form">
                                    <strong>Type:</strong>
                                    <select name="research" id="research">
                                        <option value=""></option>
                                        <option value="mathematics">Mathematics</option>
                                        <option value="engineering" selected>Engineering</option>
                                        <option value="lifeSciences">Life Sciences</option>
                                        <option value="medicineHealthcare">Medicine & Healthcare</option>
                                        <option value="socialSciences">Social Sciences</option>
                                    </select>
                                </div>
                                <div className="conference_form">
                                    <strong>Title:</strong>
                                    <textarea name="" style={{ "height": "40px" }} id="title"></textarea>
                                </div>
                                <div className="conference_form">
                                    <strong>Description:</strong>
                                    <textarea name="" style={{ "height": "80px" }} id="title"></textarea>
                                </div>

                                <button className="btn" style={{ "margin": "5px" }}>Add</button>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        );
    }
}

export default Main;