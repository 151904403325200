import React from "react";
import $ from "jquery";
import axios from 'axios';

import "../assets/css/style.css";
import logo from "../assets/img/logo.png";


const pathname = window.location.pathname;
var route = pathname.split("/");
let page = route[1];

console.log("page:" + page)

class Main extends React.Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <header>
                    <div className="logo" title="Conference Management System">
                        <img src={logo} alt="CMS" />
                        <h2>C<span className="danger">M</span>S</h2>
                    </div>
                    <div className="navbar">
                        <a href="/">
                            <span className="material-icons-sharp">home</span>
                            <h3>Home</h3>
                        </a>
                        <a href="callForPaper" className="active">
                            <span className="material-icons-sharp">ring_volume</span>
                            <h3>Call for Paper</h3>
                        </a>
                        <a href="submission">
                            <span className="material-icons-sharp">send</span>
                            <h3>Submission</h3>
                        </a>
                        <a href="register">
                            <span className="material-icons-sharp">app_registration</span>
                            <h3>Register</h3>
                        </a>
                        
                    </div>
                </header>
                <div className="container">
                    <aside>
                        <div className="profile">
                            <h3>Conferences</h3>
                            <div className="about" style={{ "height": "450px", "overflowY": "scroll" }}>
                                <h5>Mathematics</h5>
                                <p>1. Mathematical Logic and Foundations <a style={{ "color": "blue" }} href="#">Open</a></p>
                                <h5>Engineering</h5>
                                <p>1. Engineering & Industry (general) <a style={{ "color": "blue" }} href="#">Open</a>
                                    <br />2. Aviation & Spaceflight <a style={{ "color": "blue" }} href="#">Open</a></p>
                                <h5>Life Sciences</h5>
                                <p>1. Molecular Biology <a style={{ "color": "blue" }} href="#">Open</a></p>
                                <h5>Medicine & Healthcare</h5>
                                <p>1. Clinical Data Management, e-Health <a style={{ "color": "blue" }} href="#">Open</a>
                                    <br />2. Regulation in Medicine and Pharmacology <a style={{ "color": "blue" }} href="#">Open</a></p>
                                <h5>Social Sciences</h5>
                                <p>1. Economics, Business and Marketing <a style={{ "color": "blue" }} href="#">Open</a></p>
                            </div>
                        </div>
                    </aside>

                    <main>
                        <h1>Mathematical Logic and Foundations (CMS0052)</h1>
                        <ul>
                            <br />
                            <li><h3>General Submission Requirements</h3>
                                <ol>
                                    <li><strong>Originality</strong>: Papers must be original and not previously published or under consideration for publication elsewhere.</li>
                                    <li><strong>Plagiarism</strong>: Submissions should be free of plagiarism. All papers will be checked for originality using plagiarism detection tools.</li>
                                    <li><strong>Language</strong>: Papers must be written in English and carefully proofread for grammar and clarity.</li>
                                </ol>
                            </li>
                            <br />
                            <li><strong>Formatting Guidelines</strong>
                                <ol>
                                    <li><strong>File Format</strong>: Papers must be submitted in PDF format.</li>
                                    <li><strong>Length</strong>: The paper should be between 6 to 8 pages, including references, figures, and tables.</li>
                                    <li><strong>Font</strong>: Use 10-point Times New Roman or a similar font for the main body text.</li>
                                </ol>
                            </li>
                            <br />
                            <li><strong>Content Structure</strong>
                                <ol>
                                    <li><strong>Title Page</strong>: The title page should include the paper’s title, authors' names, affiliations, and contact information of the corresponding author.</li>
                                    <li><strong>Abstract</strong>: A concise summary of the paper, not exceeding 250 words.</li>
                                    <li><strong>Introduction</strong>: Introduce the problem, background, and purpose of your research.</li>
                                </ol>
                            </li>
                        </ul>
                        <br />
                        <div>
                            <button className="small_button" style={{ "margin": "5px" }}>Submit paper</button>
                            <button className="small_button" style={{ "margin": "5px" }}>Register</button>
                        </div>
                        <br />
                        <div className="home_cards">
                            <div className="eg">
                                <h3 style={{ "margin": "0px" }}>Important dates</h3>
                                <ul>
                                    <li><strong>Paper Submission Deadline:</strong> 05/11/2024</li>
                                    <li><strong>Notification of Acceptance:</strong> 10/11/2024</li>
                                    <li><strong>Camera-Ready Paper Submission Deadline:</strong> 15/11/2024</li>
                                    <li><strong>Registration Deadline:</strong> 17/11/2024</li>
                                    <li><strong>Conference Dates:</strong> 23/11/2024</li>
                                </ul>

                            </div>

                        </div>
                    </main>

                    <div className="right">
                        <div className="announcements">
                            <h2>FAQ's</h2>
                            <div className="updates">
                                <div className="message">
                                    <p>
                                        <b>How do I submit a paper?</b> <br />
                                        You can submit your paper by clicking "Submit Paper" provided in call for paper page for each conference
                                    </p>
                                </div>
                                <div className="message">
                                    <p>
                                        <b>What file formats are accepted for submission?</b> <br />
                                        Check the guidelines provided for each conference in call for paper page
                                    </p>
                                </div>
                                <div className="message">
                                    <p>
                                        <b> What is the paper review process?</b> <br />
                                        Once submitted, papers undergo a peer review process. Reviewers will assess your paper based on originality, relevance, and scientific rigor.
                                    </p>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        );
    }
}

export default Main;