import React from "react";
import $ from "jquery";
import axios from 'axios';

import "../assets/css/style.css";
import logo from "../assets/img/logo.png";


const pathname = window.location.pathname;
var route = pathname.split("/");
let page = route[1];

console.log("page:" + page)

class Main extends React.Component {

    constructor(props) {
        super(props);

    }

    submit(e) {
        e.preventDefault();
        let url = "/admin/conferences"

        if ($("#userid").val() == "") {
            alert("Enter user id");
            $("#userid").focus()
        }
        else if ($("#password").val() == "") {
            alert("Enter the password");
            $("#password").focus()
        }
        else{
            window.location.replace(url)
        }
    }

    cancel(e)
    {
        e.preventDefault();
        $("#userid").val("")
        $("#password").val("")
    }


    render() {
        return (
            <div>
                <header>
                    <div className="logo" title="Conference Management System">
                        <img src={logo} alt="CMS" />
                        <h2>C<span className="danger">M</span>S</h2>
                    </div>
                    <div className="navbar">
                        <a href="/">
                            <span className="material-icons-sharp">home</span>
                            <h3>Home</h3>
                        </a>
                    </div>
                </header>
                <div className="submission-container">
                    <form action="">
                        <h2>Admin login</h2>
                        
                        <div className="box">
                            <p className="text-muted">User ID</p>
                            <input type="text" id="userid" />
                        </div>
                        <div className="box">
                            <p className="text-muted">Password</p>
                            <input type="password" id="password" />
                        </div>
                        
                        <div className="button">
                            <input type="submit" value="Login" className="btn" onClick={(e) => this.submit(e)}/>
                            <a href="#" onClick={(e) => this.cancel(e)} className="text-muted">Cancel</a>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default Main;