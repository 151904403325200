import React from "react";
import $ from "jquery";
import axios from 'axios';

import "../assets/css/style.css";
import logo from "../assets/img/logo.png";
import user from "../assets/img/user.png";

const pathname = window.location.pathname;
var route = pathname.split("/");
let page = route[1];

console.log("page:" + page)

class Main extends React.Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        // const videoElem = document.getElementById("video");
        // const startElem = document.getElementById("start");
        // const stopElem = document.getElementById("stop");
        // // Options for getDisplayMedia()
        // var displayMediaOptions = {
        //     video: {
        //         cursor: "always",
        //         height: 600,
        //         width: 800
        //     },
        //     audio: false
        // };
        // // Set event listeners for the start and stop buttons
        // startElem.addEventListener("click", function (evt) {
        //     startCapture();
        // }, false);

        // stopElem.addEventListener("click", function (evt) {
        //     stopCapture();
        // }, false);

        // async function startCapture() {
        //     try {
        //         videoElem.srcObject = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
        //         dumpOptionsInfo();
        //     } catch (err) {
        //         console.error("Error: " + err);
        //     }
        // }

        // function stopCapture(evt) {
        //     let tracks = videoElem.srcObject.getTracks();
        //     tracks.forEach(track => track.stop());
        //     videoElem.srcObject = null;
        // }

        // function dumpOptionsInfo() {
        //     const videoTrack = videoElem.srcObject.getVideoTracks()[0];
        //     console.info("Track settings:");
        //     console.info(JSON.stringify(videoTrack.getSettings(), null, 2));
        //     console.info("Track constraints:");
        //     console.info(JSON.stringify(videoTrack.getConstraints(), null, 2));
        // }
    }

    join(e) {
        e.preventDefault();
    }

    render() {
        return (
            <div>
                <header>
                    <div className="logo" title="Conference Management System">
                        <img src={logo} alt="CMS" />
                        <h2>C<span className="danger">M</span>S</h2>
                    </div>
                    <div className="navbar">

                        <a href="/">
                            <span className="material-icons-sharp">logout</span>
                            <h3>Exit</h3>
                        </a>
                    </div>
                </header>
                <div style={{ "display": "flex" }}>


                    <main id="content" style={{ "width": "80%" }}>
                        {/* <video id="video" autoPlay playsInline muted style={{"minWidth":"99.5%", "background":"#80808082"}}></video>
                        <div>
                            <button className="btn btn-primary" id="start" style={{"marginTop":"0"}}> Start Screen Sharing</button>
                            <button className="btn btn-secondary" id="stop" style={{"marginTop":"0"}}> Stop</button>
                        </div> */}
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/XR-J9PGRBxU?si=_TMnhXnoNzYPKxJk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </main>

                    <div className="right" style={{ "width": "25%", "paddingLeft": "0" }}>
                        <div className="announcements">
                            <h2>Chat and Q&A</h2>
                            <div className="card profile-card ">

                                <div className="chat-messages">
                                    <div className="message-box-holder">
                                        <div className="message-box">
                                            Hello
                                            <br /><span className="chat-date-time">24-09-2023 2:30 PM</span>
                                        </div>
                                    </div>

                                    <div className="message-box-holder">
                                        <div className="message-sender">
                                            Noah
                                        </div>
                                        <div className="message-box message-partner">
                                            Hi.
                                            <br /><span className="chat-date-time">24-09-2023 2:32 PM</span>
                                        </div>
                                    </div>

                                    <div className="message-box-holder">
                                        <div className="message-box">
                                            There is no audio?
                                            <br /><span className="chat-date-time">24-09-2023 2:35 PM</span>
                                        </div>
                                    </div>

                                    <div className="message-box-holder">
                                        <div className="message-sender">
                                            allen
                                        </div>
                                        <div className="message-box message-partner">
                                            Turn on the speaker and check
                                            <br /><span className="chat-date-time">24-09-2023 2:36 PM</span>
                                        </div>
                                    </div>

                                    <div className="message-box-holder">
                                        <div className="message-box">
                                            Ok fine. tq
                                            <br /><span className="chat-date-time">24-09-2023 2:39 PM</span>
                                        </div>
                                    </div>

                                    <div className="message-box-holder">
                                        <div className="message-box">
                                            And how long the session will be
                                            <br /><span className="chat-date-time">24-09-2023 2:39 PM</span>
                                        </div>
                                    </div>

                                    <div className="message-box-holder">
                                        <div className="message-box">
                                            I'm struggling to know the schedule...
                                            <br /><span className="chat-date-time">24-09-2023 2:40 PM</span>
                                        </div>
                                    </div>

                                    <div className="message-box-holder">
                                        <div className="message-sender">
                                            Joe
                                        </div>
                                        <div className="message-box message-partner">
                                            Check the schedule page. select the conference for which you want to know the timing.
                                            <br /><span className="chat-date-time">24-09-2023 2:50 PM</span>
                                        </div>
                                    </div>

                                </div>

                                <div className="chat-input-holder">
                                    <textarea className="chat-input"></textarea>
                                    <input type="submit" value="Send" className="message-send pointer" />
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div >
        );
    }
}

export default Main;